import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../atoms/Navbar";
import Footer from "../atoms/Footer";
import QuillEditor from "../atoms/QuillEditor";
import { ToastContainer, toast } from "react-toastify";

const AddQuestion = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { id } = useParams();
  const [userName, setUserName] = useState("");

  const [question, setQuestion] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [option5, setOption5] = useState("");
  const [answer, setAnswer] = useState("");
  const [spinner, setSpinner] = useState(false);

  const handleCallbackQuill = (data) => {
    switch (data.to) {
      case "option1":
        setOption1(data.value);
        break;
      case "option2":
        setOption2(data.value);
        break;
      case "option3":
        setOption3(data.value);
        break;
      case "option4":
        setOption4(data.value);
        break;
      case "option5":
        setOption5(data.value);
        break;
      default:
        setQuestion(data.value);
    }
  };

  const handleErrorMessage = (data) => {
    toast.error(data, {
      position: "bottom-right",
    });
  };

  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setUserName(decoded.userName);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const back = () => {
    navigate(`/quiz/${id}`);
  };

  const saveQuestion = async () => {
    if (!question || !option1 || !option2 || !option3 || !option4 || !option5) {
      toast.error("Please complete the question.", {
        position: "bottom-right",
      });
    } else if (!answer) {
      toast.error("The answer has not been set.", {
        position: "bottom-right",
      });
    } else {
      setSpinner(true);

      try {
        const response = await axiosJWT.post(`${apiUrl}/quiz/${id}`, {
          question,
          options: [
            { key: 1, option: option1 },
            { key: 2, option: option2 },
            { key: 3, option: option3 },
            { key: 4, option: option4 },
            { key: 5, option: option5 },
          ],
          answer,
        });
        navigate(`/quiz/${id}`, {
          state: {
            age: new Date().setSeconds(new Date().getSeconds() + 1),
            msg: response.data.msg,
          },
        });
      } catch (error) {
        setSpinner(false);
        console.log(error);
      }
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={userName} />
      <div className="container mt-5 py-5">
        <div className="row">
          <div className="col-lg-8 mb-4 mb-lg-0">
            <div className="card h-100">
              <div className="card-header d-flex">
                <h5 className="my-auto me-2">Question</h5>
                <QuillEditor
                  to={"question"}
                  val={question}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                <button
                  className="btn btn-secondary btn-sm ms-2 d-none d-md-block"
                  onClick={back}
                >
                  <i class="fa-solid fa-reply me-2"></i>Back
                </button>
                <button
                  className="btn btn-success btn-sm ms-2 d-flex"
                  onClick={saveQuestion}
                  disabled={id === "q83iu" ? true : spinner}
                >
                  <div className="d-flex my-auto mx-auto">
                    {spinner ? (
                      <div
                        className="spinner-border spinner-border-sm my-auto me-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <i className="fas fa-save me-2 my-auto"></i>
                    )}
                    Save Question
                  </div>
                </button>
              </div>
              {question ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: question }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="divider d-flex align-items-center mb-4">
              <p className="text-center fw-bold mx-3 mb-0 text-muted">
                OPTIONS
              </p>
            </div>
            <div className="card mb-3">
              <div className="card-header d-flex">
                <h6 className="my-auto me-2">Option 1</h6>
                <QuillEditor
                  to={"option1"}
                  val={option1}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                {answer !== 1 ? (
                  <button
                    className="btn btn-outline-success btn-sm ms-2"
                    onClick={() => setAnswer(1)}
                  >
                    Answer
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm ms-2 d-flex">
                    <i class="fa-solid fa-check me-2 my-auto"></i>
                    <div className="my-auto">Answer</div>
                  </button>
                )}
              </div>
              {option1 ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: option1 }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
            <div className="card mb-3">
              <div className="card-header d-flex">
                <h6 className="my-auto me-2">Option 2</h6>
                <QuillEditor
                  to={"option2"}
                  val={option2}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                {answer !== 2 ? (
                  <button
                    className="btn btn-outline-success btn-sm ms-2"
                    onClick={() => setAnswer(2)}
                  >
                    Answer
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm ms-2 d-flex">
                    <i class="fa-solid fa-check me-2 my-auto"></i>
                    <div className="my-auto">Answer</div>
                  </button>
                )}
              </div>
              {option2 ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: option2 }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
            <div className="card mb-3">
              <div className="card-header d-flex">
                <h6 className="my-auto me-2">Option 3</h6>
                <QuillEditor
                  to={"option3"}
                  val={option3}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                {answer !== 3 ? (
                  <button
                    className="btn btn-outline-success btn-sm ms-2"
                    onClick={() => setAnswer(3)}
                  >
                    Answer
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm ms-2 d-flex">
                    <i class="fa-solid fa-check me-2 my-auto"></i>
                    <div className="my-auto">Answer</div>
                  </button>
                )}
              </div>
              {option3 ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: option3 }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
            <div className="card mb-3">
              <div className="card-header d-flex">
                <h6 className="my-auto me-2">Option 4</h6>
                <QuillEditor
                  to={"option4"}
                  val={option4}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                {answer !== 4 ? (
                  <button
                    className="btn btn-outline-success btn-sm ms-2"
                    onClick={() => setAnswer(4)}
                  >
                    Answer
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm ms-2 d-flex">
                    <i class="fa-solid fa-check me-2 my-auto"></i>
                    <div className="my-auto">Answer</div>
                  </button>
                )}
              </div>
              {option4 ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: option4 }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
            <div className="card">
              <div className="card-header d-flex">
                <h6 className="my-auto me-2">Option 5</h6>
                <QuillEditor
                  to={"option5"}
                  val={option5}
                  callback={handleCallbackQuill}
                  errorMsg={handleErrorMessage}
                />
                {answer !== 5 ? (
                  <button
                    className="btn btn-outline-success btn-sm ms-2"
                    onClick={() => setAnswer(5)}
                  >
                    Answer
                  </button>
                ) : (
                  <button className="btn btn-success btn-sm ms-2 d-flex">
                    <i class="fa-solid fa-check me-2 my-auto"></i>
                    <div className="my-auto">Answer</div>
                  </button>
                )}
              </div>
              {option5 ? (
                <div
                  className="card-body html-text"
                  dangerouslySetInnerHTML={{ __html: option5 }}
                ></div>
              ) : (
                <div className="card-body">...</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default AddQuestion;
