import React from "react";
import { Link } from "react-router-dom";

const NavbarParticipant = ({ name }) => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container">
        <Link to="/quizzes" className="navbar-brand ms-2 ms-md-0" href="#">
          <i className="fa-solid fa-circle-question me-2"></i>QuizApp
        </Link>
        <div className="dropdown me-2 me-md-0">
          <div type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fas fa-user"></i>
            {name ? (
              <div className="d-none d-md-inline ms-2">{name}</div>
            ) : (
              <div className="d-none d-md-inline">
                <div
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
          <div className="d-block d-md-none">
            <ul className="dropdown-menu dropdown-menu-end">
              <li className="dropdown-item d-block d-md-none">
                <i className="fas fa-user me-2"></i>
                {name}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarParticipant;
