import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

function QRModal({ id, name, msg }) {
  const appUrl = process.env.REACT_APP_APP_URL;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <i
        className="fa-solid fa-qrcode text-primary ms-2"
        onClick={handleShow}
        style={{ cursor: "pointer" }}
      ></i>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center p-4">
          <h3 className="mb-4">{name}</h3>
          <div className="d-none d-lg-block">
            <QRCodeSVG value={`${appUrl}/${id}`} width={350} height={350} />
          </div>
          <div className="d-block d-lg-none">
            <QRCodeSVG value={`${appUrl}/${id}`} width={250} height={250} />
          </div>
          <div className="mt-3">
            <Link
              onClick={() => {
                navigator.clipboard.writeText(`${appUrl}/${id}`);
                msg("Link copied.");
              }}
              title="Copy to clipboard"
            >
              {`${appUrl}/${id}`}
            </Link>
            <Link
              to={`${appUrl}/${id}`}
              target="_blank"
              title="Open link"
              className="ms-2"
            >
              <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </Link>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QRModal;
