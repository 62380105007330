import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Navbar from "../atoms/Navbar";
import Footer from "../atoms/Footer";
import AddQuizModal from "../atoms/AddQuizModal";
import { ToastContainer, toast } from "react-toastify";
import QRModal from "../atoms/QRModal";

const Quizzes = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [quizzes, setQuizzes] = useState([]);
  const [msg, setMsg] = useState(false);
  const [quizzesSpinner, setQuizzesSpinner] = useState(true);
  const [quizStartSpinner, setQuizStartSpinner] = useState([false, ""]);
  const [quizStopSpinner, setQuizStopSpinner] = useState([false, ""]);
  const [sortSpinner, setSortSpinner] = useState(false);
  const [showRow, setShowRow] = useState([false, ""]);
  const [desc, setDesc] = useState(false);

  function handleMsg(data) {
    setMsg(data);
  }

  useEffect(() => {
    refreshToken();
    getQuizzes();
    if (location.state) {
      if (new Date().getTime() < location.state.age)
        toast.success(location.state.msg, {
          position: "bottom-right",
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    toast.success(msg, {
      position: "bottom-right",
    });
    getQuizzes();
    setMsg(false);
    // eslint-disable-next-line
  }, [msg]);

  useEffect(() => {
    getQuizzes();
    // eslint-disable-next-line
  }, [desc]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setUserId(decoded.userId);
      setUserName(decoded.userName);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getQuizzes = async () => {
    setSortSpinner(true);
    try {
      const response = await axiosJWT.get(
        `${apiUrl}/quizzes${desc ? "/desc" : ""}`
      );
      setQuizzesSpinner(false);
      setQuizzes(response.data);
      setSortSpinner(false);
    } catch (error) {
      setSortSpinner(false);
    }
  };

  const startQuiz = async (id) => {
    setQuizStartSpinner([true, id]);

    try {
      await axiosJWT.get(`${apiUrl}/question/${id}`);

      await axiosJWT.patch(`${apiUrl}/quiz/${id}`, {
        active: true,
      });
      setQuizStartSpinner([false, ""]);
      toast.success("Quiz has been started.", {
        position: "bottom-right",
      });
      getQuizzes();
    } catch (error) {
      if (error.response.status === 404)
        toast.error("Quiz must have a question to start.", {
          position: "bottom-right",
        });
      setQuizStartSpinner([false, ""]);
    }
  };

  const stopQuiz = async (id) => {
    setQuizStopSpinner([true, id]);

    try {
      await axiosJWT.patch(`${apiUrl}/quiz/${id}`, {
        active: false,
      });
      setQuizStopSpinner([false, ""]);
      toast.success("Quiz has been stopped.", {
        position: "bottom-right",
      });
      getQuizzes();
    } catch (error) {
      setQuizStopSpinner([false, ""]);
    }
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={userName} />
      <div className="container mt-5 py-5">
        <div className="d-flex mb-3">
          <AddQuizModal msg={handleMsg} />
          {userId === "66302dc74d826d8daeababd8" ? (
            <Link to="/register" className="my-auto ms-auto">
              Create your own Quiz
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-hover align-middle">
            <thead>
              <tr>
                <th>#</th>
                <th className="d-none d-md-table-cell">ID</th>
                <th
                  onClick={() => (desc ? setDesc(false) : setDesc(true))}
                  style={{ cursor: "pointer" }}
                >
                  Name
                  {sortSpinner ? (
                    <div
                      className="spinner-border spinner-border-sm float-end mt-1"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : desc ? (
                    <i className="fas fa-caret-up float-end mt-1 ms-2"></i>
                  ) : (
                    <i className="fas fa-caret-down float-end mt-1 ms-2"></i>
                  )}
                </th>
                <th className="d-none d-md-table-cell">Random</th>
                <th className="d-none d-md-table-cell">Show Score</th>
                <th colSpan={3}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {!quizzesSpinner ? (
                quizzes.length !== 0 ? (
                  quizzes.map((quiz, index) => (
                    <>
                      <tr>
                        <th
                          onClick={() =>
                            showRow[0] && showRow[1] === quiz._id
                              ? setShowRow([false, quiz._id])
                              : setShowRow([true, quiz._id])
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {showRow[0] && showRow[1] === quiz._id ? (
                            <i className="fa-solid fa-caret-down me-2 d-inline d-md-none"></i>
                          ) : (
                            <i className="fa-solid fa-caret-right me-2 d-inline d-md-none"></i>
                          )}
                          {index + 1}
                        </th>
                        <td className="d-none d-md-table-cell">
                          {quiz._id}
                          <QRModal
                            id={quiz._id}
                            name={quiz.name}
                            msg={handleMsg}
                          />
                        </td>
                        <td>{quiz.name}</td>
                        <td className="d-none d-md-table-cell">
                          {quiz.random ? "Yes" : "No"}
                        </td>
                        <td className="d-none d-md-table-cell">
                          {quiz.show_score ? "Yes" : "No"}
                        </td>
                        <td width={10}>
                          {!quiz.active ? (
                            <button
                              className="btn btn-success btn-sm"
                              title="Start Quiz"
                              onClick={() => startQuiz(quiz._id)}
                              disabled={quizStartSpinner[0]}
                            >
                              {quizStartSpinner[1] === quiz._id ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <i className="fa-solid fa-play"></i>
                              )}
                            </button>
                          ) : (
                            <button
                              className="btn btn-danger btn-sm"
                              title="Stop Quiz"
                              onClick={() => stopQuiz(quiz._id)}
                              disabled={
                                quiz._id === "q83iu" ? true : quizStopSpinner[0]
                              }
                            >
                              {quizStopSpinner[1] === quiz._id ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                <i className="fa-solid fa-stop"></i>
                              )}
                            </button>
                          )}
                        </td>
                        <td width={10}>
                          <Link
                            to={`/participants/${quiz._id}`}
                            className="btn btn-info btn-sm"
                            title="Show Participants"
                          >
                            <i className="fas fa-users"></i>
                          </Link>
                        </td>
                        <td width={10}>
                          <Link
                            to={`/quiz/${quiz._id}`}
                            className="btn btn-primary btn-sm"
                            title="Edit Questions"
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </Link>
                        </td>
                      </tr>
                      {showRow[0] && showRow[1] === quiz._id ? (
                        <tr className="d-table-row d-md-none">
                          <td colSpan={9}>
                            <ul className="m-0">
                              <li>
                                <b>ID</b>: {quiz._id}
                                <QRModal
                                  id={quiz._id}
                                  name={quiz.name}
                                  msg={handleMsg}
                                />
                              </li>
                              <li>
                                <b>Random</b>: {quiz.random ? "Yes" : "No"}
                              </li>
                              <li>
                                <b>Show Score</b>:{" "}
                                {quiz.show_score ? "Yes" : "No"}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No data available in table
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Quizzes;
