import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-body-tertiary text-center text-lg-start mt-auto">
      <div className="text-center p-3">
        &copy; 2024 <Link to="/">QuizApp</Link> by{" "}
        <Link to="https://fergus.my.id" target="_blank">
          Ferdi Gusnanto
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
