import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function FinishModal({ finish }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [confirm, setConfirm] = useState(false);

  return (
    <>
      <button className="btn btn-primary ms-auto" onClick={handleShow}>
        Finish
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Finish?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="confirm"
              onClick={() => setConfirm(true)}
            />
            <label className="form-check-label" htmlFor="confirm">
              Confirm to Finish
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!confirm}
            onClick={() => finish()}
          >
            Finish
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FinishModal;
