import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import axios from "axios";
import katex from "katex";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import "katex/dist/katex.min.css";
window.katex = katex;

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="326015353769-0n1gs5f4lhb1u6q97oomndajl2anlsur.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);
