import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavbarParticipant from "../atoms/NavbarParticipant";
import Footer from "../atoms/Footer";
import FinishModal from "../atoms/FinishModal";
import { ToastContainer, toast } from "react-toastify";

const Quiz = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();

  const [participantName, setParticipantName] = useState("");
  const [index, setIndex] = useState(0);
  const [quiz, setQuiz] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([]);
  const [answer, setAnswer] = useState(0);
  const [marks, setMarks] = useState([]);
  const [spinner, setSpinner] = useState(true);

  useEffect(() => {
    refreshToken();
    getQuiz();
    if (parseInt(params.index) !== index || params.index === "0") {
      navigate("/start/1");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (questions.length !== 0 && index !== 0) {
      const Question = questions[index - 1];
      setQuestion(Question.question);

      let Options = Question.options;
      if (quiz.random) {
        Options = shuffle(Question.options);
      }
      setOptions(Options);

      userAnswers[index - 1] ? setAnswer(userAnswers[index - 1]) : setAnswer(0);
    }

    if (!index && userAnswers.length !== 0) {
      let score = 0;
      userAnswers.map((answer, index) => {
        if (answers[index] === answer) {
          score += 1;
        }
        return true;
      });
      send(Math.round((score / answers.length) * 100));
    }
    // eslint-disable-next-line
  }, [index]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/start/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setParticipantName(decoded.participantName);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/start/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        const decoded = jwtDecode(response.data.accessToken);
        setParticipantName(decoded.participantName);
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const getQuiz = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/start`);

      setQuiz(response.data.quiz);

      let questions = response.data.questions;
      if (response.data.quiz.random) {
        questions = shuffle(questions);
      }
      setQuestions(questions);
      questions.map((question, index) => {
        answers[index] = question.answer;
        return true;
      });
      setIndex(1);
      setSpinner(false);
    } catch (error) {}
  };

  const mark = (boolean) => {
    const items = [...marks];
    items[index - 1] = boolean;
    setMarks(items);
  };

  const save = (key) => {
    const items = [...userAnswers];
    items[index - 1] = key;
    setUserAnswers(items);
    setAnswer(key);
  };

  const next = () => {
    setIndex(parseInt(params.index) + 1);
    navigate(`/start/${parseInt(params.index) + 1}`);
  };

  const previous = () => {
    setIndex(parseInt(params.index) - 1);
    navigate(`/start/${parseInt(params.index) - 1}`);
  };

  const finish = () => {
    setIndex(0);
  };

  const send = async (score) => {
    const response = await axiosJWT.post(`${apiUrl}/finish`, {
      score,
    });

    navigate("/result", {
      state: {
        response: response.data,
      },
    });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <NavbarParticipant name={participantName} />
      <div className="container mt-5 py-5">
        {!spinner ? (
          <>
            <div className="d-flex mb-4">
              <h4 className="me-2 my-auto">{quiz.name}</h4>
              {quiz._id === "q83iu" ? (
                <Link to="/register" className="my-auto ms-auto">
                  Create your own Quiz
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <div className="card">
                  <div className="card-header">Table of Question</div>
                  <div className="card-body">
                    {questions.map((question, index) =>
                      marks[index] ? (
                        <button
                          key={`question${question._id}`}
                          className="btn btn-warning btn-sm me-1 mb-1"
                          onClick={() => {
                            setIndex(index + 1);
                            navigate(`/start/${index + 1}`);
                          }}
                        >
                          {index + 1}
                        </button>
                      ) : userAnswers[index] === undefined ||
                        userAnswers[index] === 0 ? (
                        <button
                          key={question._id}
                          className="btn btn-secondary btn-sm me-1 mb-1"
                          onClick={() => {
                            setIndex(index + 1);
                            navigate(`/start/${index + 1}`);
                          }}
                        >
                          {index + 1}
                        </button>
                      ) : (
                        <button
                          key={question._id}
                          className="btn btn-primary btn-sm me-1 mb-1"
                          onClick={() => {
                            setIndex(index + 1);
                            navigate(`/start/${index + 1}`);
                          }}
                        >
                          {index + 1}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-header d-flex">
                    <h6 className="my-2 me-2">
                      Question {index !== 0 ? index : ""}
                    </h6>
                    {marks[index - 1] ? (
                      <button
                        className="btn btn-warning ms-auto"
                        onClick={() => mark(false)}
                      >
                        <i className="fas fa-check me-2"></i>Mark
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-warning ms-auto"
                        onClick={() => mark(true)}
                      >
                        Mark
                      </button>
                    )}
                  </div>
                  <div className="card-body">
                    <p
                      className="html-text"
                      dangerouslySetInnerHTML={{ __html: question }}
                    ></p>
                    {options.map((option) => (
                      <div className="form-check" key={`option${option.key}`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="options"
                          id={`option${option.key}`}
                          onClick={() => save(option.key)}
                          checked={answer === option.key}
                        />
                        <label
                          className="form-check-label html-text"
                          htmlFor={`option${option.key}`}
                          dangerouslySetInnerHTML={{ __html: option.option }}
                        ></label>
                      </div>
                    ))}
                  </div>
                  <div className="card-footer d-flex">
                    {index !== 0 && index !== 1 ? (
                      <button className="btn btn-primary" onClick={previous}>
                        Previous
                      </button>
                    ) : (
                      ""
                    )}
                    {index === questions.length && index !== 0 ? (
                      userAnswers.length !== answers.length ||
                      userAnswers.includes(0) ||
                      userAnswers.includes(undefined) ? (
                        <button
                          className="btn btn-primary ms-auto"
                          onClick={() =>
                            toast.error(
                              "Please complete the questions first.",
                              {
                                position: "bottom-right",
                              }
                            )
                          }
                        >
                          Finish
                        </button>
                      ) : (
                        <FinishModal finish={finish} />
                      )
                    ) : index === 0 ? (
                      <button className="btn btn-primary ms-auto" disabled>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary ms-auto"
                        onClick={next}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Quiz;
