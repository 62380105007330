import React, { useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../atoms/Navbar";
import Footer from "../atoms/Footer";
import DeleteParticipantModal from "../atoms/DeleteParticipantModal";
import { ToastContainer, toast } from "react-toastify";
import DeleteParticipantsModal from "../atoms/DeleteParticipantsModal";

const Participants = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const { id } = useParams();
  const [quiz, setQuiz] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const handleMsg = (data) => {
    toast.success(data, {
      position: "bottom-right",
    });
    getParticipants();
  };

  useEffect(() => {
    refreshToken();
    getQuiz();
    getParticipants();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setUserName(decoded.userName);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const getQuiz = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/quiz/${id}`);
      setQuiz(response.data.quiz);
    } catch (error) {}
  };

  const getParticipants = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/participants/${id}`);
      setSpinner(false);
      setParticipants(response.data);
    } catch (error) {}
  };

  const back = () => {
    navigate("/quizzes");
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={userName} />
      <div className="container mt-5 py-5">
        {!spinner ? (
          <div className="row">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="card">
                <div className="card-header d-flex">
                  <h6 className="my-auto me-2">Quiz Details</h6>
                  <button
                    className="btn btn-secondary btn-sm ms-auto"
                    onClick={back}
                  >
                    <i className="fa-solid fa-reply me-2"></i>Back
                  </button>
                </div>
                <div className="card-body">
                  <table>
                    <tbody>
                      <tr>
                        <th>ID Quiz</th>
                        <td>: {quiz._id}</td>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>: {quiz.name}</td>
                      </tr>
                      <tr>
                        <th>Random</th>
                        <td>: {quiz.random ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>Show Score</th>
                        <td>: {quiz.show_score ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>: {quiz.active ? "Active" : "Inactive"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header d-flex">
                  <h5 className="my-auto me-2">
                    Participants ({participants.length})
                  </h5>
                  <DeleteParticipantsModal
                    id={quiz._id}
                    msg={handleMsg}
                    counter={participants.length}
                  />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover align-middle m-0">
                      <thead>
                        <tr>
                          <th width={40}>#</th>
                          <th>Name</th>
                          <th>
                            Score
                            <i className="fas fa-caret-up float-end mt-1 ms-2"></i>
                          </th>
                          <th width={80}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {participants.length !== 0 ? (
                          participants.map((participant, index) => (
                            <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{participant.name}</td>
                              <td>{participant.score}%</td>
                              <td>
                                <DeleteParticipantModal
                                  id={participant._id}
                                  msg={handleMsg}
                                  quizId={participant.quiz_id}
                                />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No data available in table
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Participants;
