import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const Result = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const navigate = useNavigate();

  const [participants, setParticipants] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    } else {
      getParticipants();
    }
    // eslint-disable-next-line
  }, []);

  const getParticipants = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/finish/${location.state.response.quizId}`
      );
      setParticipants(response.data);
    } catch (error) {}
  };

  return (
    <div className="d-flex min-vh-100">
      <div className="mx-auto my-auto text-center">
        {location.state.response.score ? (
          <div className="mb-5">
            {show ? (
              <>
                <h5 className="mb-3">Participants ({participants.length})</h5>
                <div className="table-responsive" style={{ height: 165 }}>
                  <table className="table table-bordered table-hover align-middle text-start m-0 w-100">
                    <thead>
                      <tr>
                        <th width={40}>#</th>
                        <th>Name</th>
                        <th>
                          Score
                          <i className="fas fa-caret-up float-end mt-1 ms-2"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {participants.length !== 0
                        ? participants.map((participant, index) =>
                            participant._id === location.state.response.id ? (
                              <tr className="table-primary" key={index}>
                                <th>{index + 1}</th>
                                <td>{participant.name}</td>
                                <td>{participant.score}%</td>
                              </tr>
                            ) : (
                              <tr key={index}>
                                <th>{index + 1}</th>
                                <td>{participant.name}</td>
                                <td>{participant.score}%</td>
                              </tr>
                            )
                          )
                        : ""}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <button className="btn btn-primary" onClick={() => setShow(true)}>
                Show Participants
              </button>
            )}
          </div>
        ) : (
          ""
        )}

        {location.state ? (
          <>
            {location.state.response.score ? (
              <>
                <h4>{location.state.response.msg}</h4>
                <p className="mb-5">Score: {location.state.response.score}%</p>
              </>
            ) : (
              <>
                <h4 className="mb-5">{location.state.response.msg}</h4>
              </>
            )}
          </>
        ) : (
          ""
        )}
        <Link to={"/"}>Go to home page</Link>
      </div>
    </div>
  );
};

export default Result;
