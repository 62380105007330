import { useMemo, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

function QuillEditor({ to, callback, val, errorMsg }) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setValue(val);
  };

  const quillRef = useRef();
  const [value, setValue] = useState(val);
  let title = "";
  switch (to) {
    case "option1":
      title = "Option 1";
      break;
    case "option2":
      title = "Option 2";
      break;
    case "option3":
      title = "Option 3";
      break;
    case "option4":
      title = "Option 4";
      break;
    case "option5":
      title = "Option 5";
      break;
    default:
      title = "Question";
  }

  const modules = useMemo(
    () => ({
      imageActions: {},
      imageFormats: {},
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote", "formula"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ align: [] }],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => quillImageCallback(),
        },
      },
    }),
    // eslint-disable-next-line
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "formula",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "code-block",
    "align",
    "float",
  ];

  const quillImageCallback = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024;
      if (input.files[0].size > maxSize) {
        errorMsg("Max file size is 2MB.");
      } else {
        const formData = new FormData();
        formData.append("image", file);

        const response = await axios.get(`${apiUrl}/token`);
        const album = await axios.post(`${apiUrl}/album`, formData, {
          headers: {
            Authorization: `Bearer ${response.data.accessToken}`,
          },
        });

        const quil = quillRef.current.getEditor();
        const range = quil.getSelection();
        quil.insertEmbed(range ? range.index : 0, "image", album.data.link);
      }
    };
  };

  const save = async () => {
    callback({ to, value: value });
    handleClose();
  };

  return (
    <>
      <button className="btn btn-primary btn-sm ms-auto" onClick={handleShow}>
        <i className="fas fa-pencil"></i>
      </button>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal-lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactQuill
            ref={quillRef}
            value={value}
            onChange={setValue}
            modules={modules}
            formats={formats}
            theme="snow"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={save}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuillEditor;
