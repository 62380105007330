import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinnerLogin, setSpinnerLogin] = useState();
  const [spinnerGoogle, setSpinnerGoogle] = useState();

  useEffect(() => {
    refreshTokenUser();
    if (location.state) {
      if (new Date().getTime() < location.state.age)
        toast.success(location.state.msg, {
          position: "bottom-right",
        });
    }
    // eslint-disable-next-line
  }, []);

  const refreshTokenUser = async () => {
    try {
      await axios.get(`${apiUrl}/token`);
      navigate("/quizzes");
    } catch (error) {}
  };

  const login = async (e) => {
    e.preventDefault();
    setSpinnerLogin(true);

    try {
      const response = await axios.post(`${apiUrl}/login`, {
        email,
        password,
      });
      navigate("/quizzes", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: response.data.msg,
        },
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
      setSpinnerLogin(false);
    }
  };

  const continueWithGoogle = useGoogleLogin({
    onSuccess: async (responseGoogle) => {
      try {
        setSpinnerGoogle(true);
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${responseGoogle.access_token}`,
            },
            withCredentials: false,
          }
        );
        const name = res.data.name;
        const email = res.data.email;
        const response = await axios.post(`${apiUrl}/google`, {
          name,
          email,
        });
        navigate("/quizzes", {
          state: {
            age: new Date().setSeconds(new Date().getSeconds() + 1),
            msg: response.data.msg,
          },
        });
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.msg, {
            position: "bottom-right",
          });
        }
        setSpinnerGoogle(false);
      }
    },
  });

  return (
    <section className="vh-100">
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5 py-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-4 p-sm-5 text-center">
                <h3 className="mb-4 mb-sm-5">Login</h3>
                <form onSubmit={login}>
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      required
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      required
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  <div className="d-flex mb-4">
                    <Link to="/register">Register</Link>
                    <Link to="/guest" className="ms-auto">
                      Login as Guest
                    </Link>
                  </div>
                  <button
                    className="btn btn-primary btn-lg w-100 d-flex"
                    type="submit"
                    disabled={spinnerLogin}
                  >
                    <div className="d-flex my-auto mx-auto">
                      {spinnerLogin ? (
                        <div
                          className="spinner-border spinner-border-sm my-auto me-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Login
                    </div>
                  </button>
                </form>
                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
                </div>
                <button
                  className="btn btn-lg btn-danger w-100 d-flex"
                  type="button"
                  onClick={() => continueWithGoogle()}
                  disabled={spinnerGoogle}
                >
                  <div className="d-flex my-auto mx-auto">
                    {spinnerGoogle ? (
                      <div
                        className="spinner-border spinner-border-sm my-auto me-2"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      ""
                    )}
                    <i className="fab fa-google my-auto me-2"></i>Continue with
                    Google
                  </div>
                </button>
              </div>
              <div className="card-footer text-center">
                &copy; 2024 <Link to="/">QuizApp</Link> by{" "}
                <Link to="https://fergus.my.id" target="_blank">
                  Ferdi Gusnanto
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Login;
