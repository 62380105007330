import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Quiz from "./components/pages/Quiz";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Guest from "./components/pages/Guest";
import Quizzes from "./components/pages/Quizzes";
import Questions from "./components/pages/Questions";
import AddQuestion from "./components/pages/AddQuestion";
import EditQuestion from "./components/pages/EditQuestion";
import Result from "./components/pages/Result";
import Participants from "./components/pages/Participants";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/participants/:id" element={<Participants />} />
          <Route path="/quiz/:quizId/:questionId" element={<EditQuestion />} />
          <Route path="/quiz/:id/add" element={<AddQuestion />} />
          <Route path="/quiz/:id" element={<Questions />} />
          <Route path="/quizzes" element={<Quizzes />} />
          <Route path="/guest" element={<Guest />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/result" element={<Result />} />
          <Route path="/start/:index" element={<Quiz />} />
          <Route path="/:id" element={<Home />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
