import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DeleteParticipantsModal(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [spinner, setSpinner] = useState(false);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/login");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const deleteParticipant = async () => {
    setSpinner(true);

    try {
      const response = await axiosJWT.delete(
        `${apiUrl}/participants/${params.id}`
      );
      handleClose();
      setSpinner(false);
      params.msg(response.data.msg);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  return (
    <>
      <button
        className="btn btn-danger btn-sm ms-auto"
        onClick={handleShow}
        disabled={!params.counter}
      >
        <i className="fas fa-trash me-2"></i>Delete All
      </button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Delete All Participants</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={deleteParticipant}
            className="d-flex"
            disabled={params.id === "q83iu" ? true : spinner}
          >
            <div className="d-flex my-auto mx-auto">
              {spinner ? (
                <div
                  className="spinner-border spinner-border-sm my-auto me-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Delete
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteParticipantsModal;
