import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

function LogoutModal() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [spinner, setSpinner] = useState(false);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const logout = async () => {
    setSpinner(true);

    try {
      const response = await axiosJWT.delete(`${apiUrl}/logout`);
      handleClose();
      setSpinner(false);
      navigate("/login", {
        state: {
          age: new Date().setSeconds(new Date().getSeconds() + 1),
          msg: response.data.msg,
        },
      });
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  return (
    <>
      <Link className="dropdown-item" onClick={handleShow}>
        <i className="fa-solid fa-right-from-bracket me-2"></i>
        Logout
      </Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        className="modal-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            type="submit"
            className="d-flex"
            onClick={() => logout()}
            disabled={spinner}
          >
            <div className="d-flex my-auto mx-auto">
              {spinner ? (
                <div
                  className="spinner-border spinner-border-sm my-auto me-2"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                ""
              )}
              Logout
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogoutModal;
