import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Navbar from "../atoms/Navbar";
import Footer from "../atoms/Footer";
import { ToastContainer, toast } from "react-toastify";
import EditQuizModal from "../atoms/EditQuizModal";
import DeleteQuestionModal from "../atoms/DeleteQuestionModal";
import DeleteQuizModal from "../atoms/DeleteQuizModal";

const Questions = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [userName, setUserName] = useState("");
  const [quiz, setQuiz] = useState("");
  const [questions, setQuestions] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [msg, setMsg] = useState(false);

  function handleMsg(data) {
    setMsg(data);
  }

  const handleDelete = (data) => {
    navigate("/quizzes", {
      state: {
        age: new Date().setSeconds(new Date().getSeconds() + 1),
        msg: data,
      },
    });
  };

  useEffect(() => {
    refreshToken();
    getQuiz();
    if (location.state) {
      if (new Date().getTime() < location.state.age)
        toast.success(location.state.msg, {
          position: "bottom-right",
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    toast.success(msg, {
      position: "bottom-right",
    });
    getQuiz();
    setMsg(false);
    // eslint-disable-next-line
  }, [msg]);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/token`);
      const decoded = jwtDecode(response.data.accessToken);
      setUserName(decoded.userName);
    } catch (error) {
      if (error.response) {
        navigate("/");
      }
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const back = () => {
    navigate("/quizzes");
  };

  const getQuiz = async () => {
    try {
      const response = await axiosJWT.get(`${apiUrl}/quiz/${params.id}`);
      setSpinner(false);
      setQuiz(response.data.quiz);
      setQuestions(response.data.questions);
    } catch (error) {}
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Navbar name={userName} />
      <div className="container mt-5 py-5">
        {!spinner ? (
          <div className="row">
            <div className="col-lg-4 mb-3 mb-lg-0">
              <div className="card">
                <div className="card-header d-flex">
                  <h6 className="my-auto me-2">Details</h6>
                  <button
                    className="btn btn-secondary btn-sm ms-auto"
                    onClick={back}
                  >
                    <i className="fa-solid fa-reply me-2"></i>Back
                  </button>
                  <EditQuizModal
                    id={quiz._id}
                    name={quiz.name}
                    random={quiz.random}
                    showScore={quiz.show_score}
                    msg={handleMsg}
                  />
                </div>
                <div className="card-body">
                  <table>
                    <tbody>
                      <tr>
                        <th>ID Quiz</th>
                        <td>: {quiz._id}</td>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>: {quiz.name}</td>
                      </tr>
                      <tr>
                        <th>Random</th>
                        <td>: {quiz.random ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>Show Score</th>
                        <td>: {quiz.show_score ? "Yes" : "No"}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>: {quiz.active ? "Active" : "Inactive"}</td>
                      </tr>
                    </tbody>
                  </table>
                  <DeleteQuizModal id={quiz._id} msg={handleDelete} />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-header d-flex">
                  <h5 className="my-auto me-2">
                    Questions ({questions.length})
                  </h5>
                  <Link
                    to={`/quiz/${quiz._id}/add`}
                    className="btn btn-primary btn-sm ms-auto"
                  >
                    <i className="fas fa-plus me-2"></i>Add Question
                  </Link>
                </div>
                <div className="card-body">
                  {questions.length !== 0 ? (
                    <table className="table">
                      <tbody>
                        {questions.map((question, index) => (
                          <tr key={question._id}>
                            <td className="py-3" width={10}>
                              {index + 1}.
                            </td>
                            <td className="py-3">
                              <span
                                className="html-text"
                                dangerouslySetInnerHTML={{
                                  __html: question.question,
                                }}
                              ></span>
                              <ul className="list-unstyled m-0">
                                {question.options.map((option, index) => (
                                  <li key={index} className="d-flex">
                                    {option.key === question.answer ? (
                                      <i className="fa-solid fa-circle-check me-2 mt-1"></i>
                                    ) : (
                                      <i className="fa-regular fa-circle me-2 mt-1"></i>
                                    )}
                                    <div
                                      className="html-text d-inline"
                                      dangerouslySetInnerHTML={{
                                        __html: option.option,
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td width={60} className="text-end py-3">
                              <Link
                                to={`/quiz/${quiz._id}/${question._id}`}
                                className="fas fa-pencil text-decoration-none me-2"
                                style={{ cursor: "pointer" }}
                                title="Edit Question"
                              ></Link>
                              <DeleteQuestionModal
                                quizId={quiz._id}
                                questionId={question._id}
                                msg={handleMsg}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="text-center">No questions</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Questions;
