import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/esm/FloatingLabel";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EditQuizModal(params) {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setName(params.name);
    setRandom(params.random);
    setShowScore(params.showScore);
    setShow(true);
  };

  const [name, setName] = useState("");
  const [random, setRandom] = useState(true);
  const [show_score, setShowScore] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      try {
        const response = await axios.get(`${apiUrl}/token`);
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
      } catch (error) {
        if (error.response) {
          navigate("/");
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const updateQuiz = async (e) => {
    e.preventDefault();
    setSpinner(true);

    try {
      const response = await axiosJWT.patch(`${apiUrl}/quiz/${params.id}`, {
        name,
        random,
        show_score,
      });
      handleClose();
      setSpinner(false);
      params.msg(response.data.msg);
    } catch (error) {
      setSpinner(false);
      console.log(error);
    }
  };

  return (
    <>
      <button
        className="btn btn-success btn-sm ms-2"
        Edit
        Quiz
        onClick={handleShow}
      >
        <i className="fa-solid fa-pencil me-2"></i>Edit Quiz
      </button>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Edit Quiz</Modal.Title>
        </Modal.Header>
        <Form onSubmit={updateQuiz}>
          <Modal.Body>
            <FloatingLabel label="Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </FloatingLabel>
            <Row>
              <Col xs={6}>
                <FloatingLabel label="Random">
                  <Form.Select
                    value={random}
                    onChange={(e) => setRandom(e.target.value)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col xs={6}>
                <FloatingLabel label="Show Score">
                  <Form.Select
                    value={show_score}
                    onChange={(e) => setShowScore(e.target.value)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="d-flex"
              disabled={params.id === "q83iu" ? true : spinner}
            >
              <div className="d-flex my-auto mx-auto">
                {spinner ? (
                  <div
                    className="spinner-border spinner-border-sm my-auto me-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  ""
                )}
                Update
              </div>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default EditQuizModal;
