import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(params.id ? params.id : "");
  const [name, setName] = useState("");
  const [quizName, setQuizName] = useState("");
  const [spinnerNext, setSpinnerNext] = useState(false);
  const [spinnerStart, setSpinnerStart] = useState(false);

  useEffect(() => {
    refreshToken();
    if (params.id) getQuiz();
    // eslint-disable-next-line
  }, []);

  const refreshToken = async () => {
    try {
      await axios.get(`${apiUrl}/start/token`);
      navigate("/start/1");
    } catch (error) {}
  };

  const getQuiz = async () => {
    try {
      const response = await axios.get(`${apiUrl}/${id}`);
      setQuizName(response.data.name);
      navigate(`/${response.data._id}`);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
        navigate("/");
      }
    }
  };

  const next = async (e) => {
    e.preventDefault();
    setSpinnerNext(true);

    try {
      const response = await axios.get(`${apiUrl}/${id}`);
      setQuizName(response.data.name);
      navigate(`/${id}`);
      setSpinnerNext(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
        navigate("/");
        setSpinnerNext(false);
      }
    }
  };

  const startQuiz = async (e) => {
    e.preventDefault();
    setSpinnerStart(true);

    try {
      await axios.post(`${apiUrl}`, {
        id,
        name,
      });
      navigate("/start/1");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.msg, {
          position: "bottom-right",
        });
      }
      setSpinnerStart(false);
    }
  };

  return (
    <section className="d-flex flex-column min-vh-100">
      <div className="container my-auto">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8 col-lg-7 col-xl-6">
            <img className="img-fluid" src="/quiz.png" alt="" />
          </div>
          <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 py-3">
            <h1 className="text-center m-0">QuizApp</h1>
            {!params.id ? (
              <div>
                <form onSubmit={next} className="mt-5">
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="quizId"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                      placeholder="Enter Quiz ID"
                      required
                    />
                    <label htmlFor="quizId">Enter Quiz ID</label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg w-100 d-flex"
                    disabled={spinnerNext}
                  >
                    <div className="d-flex my-auto mx-auto">
                      {spinnerNext ? (
                        <div
                          className="spinner-border spinner-border-sm my-auto me-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Next
                    </div>
                  </button>
                </form>
                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mx-3 mb-0 text-muted">OR</p>
                </div>
                <button
                  className="btn btn-dark btn-lg w-100"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Create your own Quiz
                </button>
              </div>
            ) : (
              <div>
                <p className="text-center">{quizName}</p>
                <form onSubmit={startQuiz} className="mt-5">
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      value={id}
                      placeholder="Enter Quiz ID"
                      disabled
                    />
                    <label htmlFor="quizId">Enter Quiz ID</label>
                  </div>
                  <div className="form-floating mb-4">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Your name"
                      required
                    />
                    <label htmlFor="name">Your name</label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg w-100 mb-2 d-flex"
                    disabled={spinnerStart}
                  >
                    <div className="d-flex my-auto mx-auto">
                      {spinnerStart ? (
                        <div
                          className="spinner-border spinner-border-sm my-auto me-2"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      Start Quiz
                    </div>
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-lg w-100"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <footer className="text-center mt-auto py-3">
        &copy; 2024 <Link to="/">QuizApp</Link> by{" "}
        <Link to="https://fergus.my.id" target="_blank">
          Ferdi Gusnanto
        </Link>
      </footer>
    </section>
  );
};

export default Home;
